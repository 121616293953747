import React, {useState, useRef} from 'react';

function Ecology() {
  return (
    <div className='campInfo'>
        <p className='specs'></p>
        <p className='title'>Ecology</p>
        <div className='info-container'>
          <div className='info-width'>
            <p className='info'>
              Our Ecology and Technology project focuses on the exploration of advanced farming through small-scale class farms integrated 
              with analytic devices such as the Microsoft FarmBeat. Students will explore this large project throughout the week and will
              accompany it with a mini-project involving the creation of a terrarium. Additionally, students will take a field trip to
              Bellevue Botanical Garden to understand and explore various niches in global environments. To finish off, presentations
              and class discussions will be held to encapsulate the week's learning!
            </p>
          </div>
          
      </div>
    </div>
  );
}

export default Ecology;